import { useState } from "react";
import { ErrorMessage } from "../form/styles";
import PopupView from "../popupview";
import InfoBoxItem from "./info";
import { InputContainer, Label } from "./styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ImageUploader from "../imageselector";
import { IconButton } from "../elements";
import { noimage } from "../../project/brand";
import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 200px;
  max-height: 100px;
  border-radius: 12px;
  margin-bottom: 10px;
`;
export const ImagePicker = (props) => {
  const { t } = useTranslation();
  const [isImageOpen, setIsImageOpen] = useState(null); // Store selected images by key
  const themeColors = useSelector((state) => state.themeColors);
  const { DynamicRatio = false } = props;
  const { height, width } = DynamicRatio ? props.formValues : props.references;
  return (
    <InputContainer className={`textarea ${props.dynamicClass ?? ""}`}>
      <InfoBoxItem info={props.info} />
      <Label theme={themeColors} className={`${!props.value?.length > 0 ? "shrink" : ""}`}>
        {`${t(props.label)}${props.required ? " *" : ""}`}
      </Label>
      <StyledImage
        onError={(e) => {
          e.target.src = noimage; // Hide the image on error
        }}
        style={{ aspectRatio: `${width}/${height}` }}
        src={props.value ?? noimage}
        alt="Dynamic"
      />
      <IconButton align="edit" label={`${props.value ? "Change Image" : "Choose Image"} (${width}/${height})`} icon="upload" ClickEvent={() => setIsImageOpen({ key: props.name, label: props.label, height: height ?? props.height, width: width ?? props.width })} />
      {/* <Button value="Choose Image" ClickEvent={() => setIsImageOpen({ key: props.name, label: props.label, height: height ?? props.height, width: width ?? props.width })}></Button> */}
      {isImageOpen && (
        <PopupView
          // Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={
            <ImageUploader
              // setLoaderBox={setLoaderBox}
              defaultRatio={isImageOpen}
              references={props.references}
              onSelect={(dataUrl) => {
                props.onChange(dataUrl, props.id, props.type);
                setIsImageOpen(null);
              }}
            ></ImageUploader>
          }
          closeModal={() => {
            setIsImageOpen(null);
          }}
          itemTitle={{
            name: "title",
            type: "text",
            collection: "",
          }}
          openData={{
            data: {
              _id: "",
              title: ` ${isImageOpen.label} : Choose Image of ${isImageOpen.width}:${isImageOpen.height} ratio`,
            },
          }} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"medium"}
        ></PopupView>
      )}
      {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info mixed" : "mixed"}`} dangerouslySetInnerHTML={{ __html: props.error }} />}
    </InputContainer>
  );
};

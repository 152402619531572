import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import ImageSelector from "../imageselector";
import PopupView from "../popupview";
import { ElementContainer, IconButton, IconButtonTransparent, TextArea, TextBox } from "../elements";
import { InputContainer, Label } from "../input/styles";
import { Accordian, AccordianContent, AccordianTitle } from "./styles";
import { GetIcon } from "../../../icons";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

// Memoized input components
const MemoizedTextBox = React.memo(TextBox);
const MemoizedTextArea = React.memo(TextArea);

const DynamicSidebar = ({ item, onChange, fieldData, childData, t, setLoaderBox, onChildChange, deleteChild = () => {} }) => {
  const [fields] = useState(JSON.parse(item.fields?.length > 0 ? item.fields : "[]"));
  const [isImageOpen, setIsImageOpen] = useState(null);
  const [openChildAccordions, setOpenChildAccordions] = useState({});

  const handleChange = useCallback(
    (key, value) => {
      onChange(key, value);
    },
    [onChange]
  );

  const handleChildChange = useCallback(
    (childIndex, parentKey, fieldKey, value) => {
      onChildChange(childIndex, parentKey, fieldKey, value);
    },
    [onChildChange]
  );

  const toggleChildAccordion = useCallback((parentIndex, childIndex) => {
    const key = `${parentIndex}-${childIndex}`;
    setOpenChildAccordions((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  const renderInputField = useMemo(() => {
    return fields.map((field) => {
      const key = field.name;
      if (field.type === "list") {
        return null;
      }

      switch (field.type) {
        case "text":
          return <MemoizedTextBox name={key} key={"side" + key} value={fieldData?.[key] ?? field.sampleText} placeholder={field.label} label={field.label} onChange={(value) => handleChange(key, value)} />;
        case "image":
          return (
            <InputContainer key={"side" + key} className="box">
              <Label className="stick">{`${t(field.label)} (${field.width ?? 1} : ${field.height ?? 1})`}</Label>
              <StyledImage style={{ aspectRatio: `${field.width ?? 1}/${field.height ?? 1}` }} src={fieldData?.[key] ?? field.sampleImage} alt="Dynamic" />
              <IconButtonTransparent
                align="edit"
                icon="edit"
                ClickEvent={() =>
                  setIsImageOpen({
                    key,
                    label: field.label,
                    height: field.height,
                    width: field.width,
                  })
                }
              />
            </InputContainer>
          );
        case "textarea":
          return <MemoizedTextArea name={key} key={"side" + key} value={fieldData?.[key] ?? field.sampleText} placeholder={field.label} label={field.label} onChange={(value) => handleChange(key, value)} />;
        default:
          return <MemoizedTextArea name={key} key={"side" + key} value={fieldData?.[key] ?? field.sampleText} placeholder={field.label} label={field.label} onChange={(value) => handleChange(key, value)} />;
      }
    });
  }, [fields, fieldData, t, handleChange]);

  const renderChildFields = useMemo(() => {
    return fields
      .filter((item) => item.type === "list")
      .map((item, parentIndex) => {
        const data = childData[item.name];
        const dataset = item.dataset ?? [];

        return (
          <div key={`list-${parentIndex}`}>
            <Accordian>
              <AccordianTitle style={{ cursor: "pointer" }}>
                <span>
                  {item.label} ({data?.length ?? 0} items)
                </span>
              </AccordianTitle>

              <AccordianContent>
                {data?.map((_, childIndex) => {
                  const child = data[childIndex];
                  const isOpen = openChildAccordions[`${parentIndex}-${childIndex}`];

                  return (
                    <Accordian key={`Accordion-${childIndex}`}>
                      <AccordianTitle onClick={() => toggleChildAccordion(parentIndex, childIndex)} style={{ cursor: "pointer" }}>
                        <span>
                          <GetIcon icon={isOpen ? "up" : "down"} />
                          {item.label} - {childIndex + 1}
                        </span>
                        <IconButton
                          align="small delete"
                          icon="delete"
                          ClickEvent={(e) => {
                            e.stopPropagation();
                            deleteChild(childIndex, item.name);
                          }}
                        />
                      </AccordianTitle>
                      {isOpen && (
                        <AccordianContent>
                          {item.fields.map((field) => {
                            const fieldKey = field.name;
                            switch (field.type) {
                              case "text":
                                return <MemoizedTextBox name={fieldKey} key={fieldKey} value={child[fieldKey] || dataset[childIndex]?.[fieldKey] || field.sampleText} placeholder={field.label} label={field.label} onChange={(value) => handleChildChange(childIndex, item.name, fieldKey, value)} />;
                              case "image":
                                return (
                                  <InputContainer key={fieldKey} className="box">
                                    <Label className="stick">{`${t(field.label)} (${field.width ?? 1} : ${field.height ?? 1})`}</Label>
                                    <StyledImage style={{ aspectRatio: `${field.width ?? 1}/${field.height ?? 1}` }} src={child[fieldKey] || dataset[childIndex]?.[fieldKey] || field.sampleImage} alt="Dynamic" />
                                    <IconButtonTransparent
                                      align="edit"
                                      icon="edit"
                                      ClickEvent={() =>
                                        setIsImageOpen({
                                          key: fieldKey,
                                          child: {
                                            index: childIndex,
                                            parentKey: item.name,
                                            key: fieldKey,
                                          },
                                          label: field.label,
                                          height: field.height,
                                          width: field.width,
                                        })
                                      }
                                    />
                                  </InputContainer>
                                );
                              case "textarea":
                                return <MemoizedTextArea name={fieldKey} key={fieldKey} value={child[fieldKey] || dataset[childIndex]?.[fieldKey] || field.sampleText} placeholder={field.label} label={field.label} onChange={(value) => handleChildChange(childIndex, item.name, fieldKey, value)} />;
                              default:
                                return <MemoizedTextArea name={fieldKey} key={fieldKey} value={child[fieldKey] || dataset[childIndex]?.[fieldKey] || field.sampleText} placeholder={field.label} label={field.label} onChange={(value) => handleChildChange(childIndex, item.name, fieldKey, value)} />;
                            }
                          })}
                        </AccordianContent>
                      )}
                    </Accordian>
                  );
                })}
              </AccordianContent>
            </Accordian>
          </div>
        );
      });
  }, [fields, childData, openChildAccordions, t, handleChildChange, toggleChildAccordion, deleteChild]);

  return (
    fields && (
      <FormContainer>
        {fields && fields.some((item) => item.type !== "list") > 0 && (
          <ElementContainer className="column">
            <div>
              <Accordian>
                <AccordianTitle style={{ cursor: "pointer" }}>Main Content</AccordianTitle>
                <AccordianContent>{renderInputField}</AccordianContent>
              </Accordian>
            </div>
          </ElementContainer>
        )}
        {Object.keys(childData ?? {})?.length > 0 && <ElementContainer className="column">{renderChildFields}</ElementContainer>}
        {isImageOpen && (
          <PopupView
            popupData={
              <ImageSelector
                setLoaderBox={setLoaderBox}
                defaultRatio={isImageOpen}
                onSelect={(dataUrl) => {
                  if (isImageOpen.child) {
                    onChildChange(isImageOpen.child.index, isImageOpen.child.parentKey, isImageOpen.child.key, dataUrl);
                  } else {
                    onChange(isImageOpen.key, dataUrl);
                  }
                  setIsImageOpen(null);
                }}
              />
            }
            closeModal={() => setIsImageOpen(null)}
            itemTitle={{
              name: "title",
              type: "text",
              collection: "",
            }}
            openData={{
              data: {
                _id: "",
                title: ` ${isImageOpen.label} : Choose Image of ${isImageOpen.width}:${isImageOpen.height} ratio`,
              },
            }}
            customClass={"medium"}
          />
        )}
      </FormContainer>
    )
  );
};

export default React.memo(DynamicSidebar);

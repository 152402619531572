import styled from "styled-components";

export const ImageUploaderContainer = styled.div`
  padding: 0 30px 30px;
`;

export const DropzoneContainer = styled.div`
  border: 2px dashed #d1d5db;
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  ${({ isDragActive }) =>
    isDragActive
      ? `  
        border-color: #3b82f6;  
        background-color: #e0e7ff;  
      `
      : ""}
`;

export const CropperContainer = styled.div`
  position: relative;
  height: 18rem;
  .size {
    position: absolute;
    padding: 10px;
  }
`;

export const AspectRatioContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1rem;
`;

export const Ratio = styled.div`
  top: 12px;
  position: absolute;
  font-size: 10px;
  background-color: #ffffffad;
  margin: 5px 0px 0px;
  padding: 5px 10px 5px 9px;
  border-radius: 0px 10px 12px 0px;
  width: 80%;
`;

export const ImageGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
`;

export const ImageWrapper = styled.div`
  position: relative;
  cursor: auto;
  border: 0px solid rgb(233, 233, 233);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  padding: 10px;
  height: 125px;
  border-radius: 12px;
  img {
    background: lightgray;
    width: 100%;
    min-height: 80px;
    max-height: 80px;
    object-fit: contain;
    border-radius: 12px;
    margin-bottom: 5px;
  }
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  &.open {
    background-color: white;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

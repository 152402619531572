export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export const getCroppedImg = async (imageSrc, pixelCrop, options = {}) => {
  const {
    maxFileSize = 0.5 * 1024 * 1024, // 1MB default max size
    qualitySteps = [0.5], // Quality reduction steps
    targetMimeType = null, // Optional mime type override
  } = options;

  // Get original file info
  const originalImage = await fetch(imageSrc);
  const originalBlob = await originalImage.blob();
  const originalFormat = targetMimeType || originalBlob.type;
  const originalSize = originalBlob.size;

  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  // Set canvas dimensions to cropped size
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Draw the cropped image
  ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

  // Dynamic compression function
  const compressImage = (quality) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas to Blob failed"));
            return;
          }
          // Generate filename with correct extension
          const extension = originalFormat.split("/")[1];
          const filename = `croppedImage.${extension}`;

          // Create File object from blob
          const croppedFile = new File([blob], filename, {
            type: originalFormat,
            lastModified: new Date().getTime(),
          });

          resolve({
            file: croppedFile,
            size: blob.size,
            quality,
          });
        },
        originalFormat,
        quality
      );
    });
  };

  // Iterative compression to meet size constraints
  let compressedResult;
  for (const quality of qualitySteps) {
    compressedResult = await compressImage(quality);

    // Log compression details
    console.log(`Compression Attempt:  
        - Original Size: ${(originalSize / 1024).toFixed(2)} KB  
        - Compressed Size: ${(compressedResult.size / 1024).toFixed(2)} KB  
        - Quality: ${compressedResult.quality}  
        - Reduction: ${((1 - compressedResult.size / originalSize) * 100).toFixed(2)}%`);

    // Break if size is acceptable
    if (compressedResult.size <= maxFileSize) {
      break;
    }
  }

  // Fallback if size still too large
  if (compressedResult.size > maxFileSize) {
    console.warn("Unable to compress image to target size");
  }

  return compressedResult.file;
};

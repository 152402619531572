import React, { useState } from "react";
import { IconButtonTransparent } from "../elements";
import PopupView from "../popupview";
import ImageUploader, { ratios } from ".";
import styled from "styled-components";
import { InputContainer, Label as StickyLabel } from "../input/styles";
import { useTranslation } from "react-i18next";
import { noimage } from "../../project/brand";
import { useSelector } from "react-redux";
import { Label } from "../select/styles";

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
export const ImageButton = ({ label, onChange, setLoaderBox, url, name, height = 1, width = 3 }) => {
  const [isImageOpen, setIsImageOpen] = useState(null);
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const [currentRatio] = useState(ratios.find((item) => height === item.height && width === item.width));
  return (
    <>
      <Label theme={themeColors} className="stick">{`${t(label)}`}</Label>
      <InputContainer key={"side" + name} className="box">
        <StickyLabel theme={themeColors} className="stick">{`${width} : ${height}`}</StickyLabel>
        <StyledImage
          onError={(e) => {
            e.target.src = noimage; // Hide the image on error
          }}
          style={{ aspectRatio: `${width}/${height}` }}
          src={url ?? noimage}
          alt="Dynamic"
        />
        <IconButtonTransparent
          align="edit"
          icon="edit"
          ClickEvent={() =>
            setIsImageOpen({
              key: name,
              label,
              height,
              width,
            })
          }
        />
      </InputContainer>
      {isImageOpen && currentRatio && (
        <PopupView
          popupData={
            <ImageUploader
              setLoaderBox={setLoaderBox}
              defaultRatio={isImageOpen}
              onSelect={(dataUrl) => {
                onChange(isImageOpen.key, dataUrl);
                setIsImageOpen(null);
              }}
            />
          }
          closeModal={() => setIsImageOpen(null)}
          itemTitle={{
            name: "title",
            type: "text",
            collection: "",
          }}
          openData={{
            data: {
              _id: "",
              title: `${isImageOpen.label} | ${currentRatio.value} (${isImageOpen.width}:${isImageOpen.height} ratio)`,
            },
          }}
          customClass={"medium"}
        />
      )}
    </>
  );
};

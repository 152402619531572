import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getData } from "../../../../../backend/api";

const Container = styled.div`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const CarouselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  gap: 16px;

`;

const Card = styled.div`
  flex: 0 0 250px;
  border-radius: 8px;
  /* background: #fff; */
  text-align: center;
  padding-bottom: 16px;
  /* padding: 10px; */
  img {
    width: 100%;
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  h3 {
    color: black;
    margin: 0px 0 10px 0;
    font-size: 15px;
  }
`;

const ChooseButton = styled.button`
  background: #094b96;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 12px 0;
  border-radius: 8px;
  &:hover {
    background: #1d4ed8;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => (props.active ? "#094b96" : "#ddd")};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;

const TemplateList = ({ onTemplateSelect }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  // Number of templates per page
  const TEMPLATES_PER_PAGE = 3;

  useEffect(() => {
    getData({}, "template/select")
      .then((response) => {
        setTemplates(response.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  const totalPages = Math.ceil(templates.length / TEMPLATES_PER_PAGE);

  const handleDotClick = (index) => {
    setCurrentPage(index);
  };

  // Calculate the transform for the carousel
  const translateX = -(currentPage * (250 * TEMPLATES_PER_PAGE + 16 * (TEMPLATES_PER_PAGE - 1)));

  return (
    <Container>
      <Title>Choose Template</Title>
      <CarouselWrapper>
        <Inner style={{ transform: `translateX(${translateX}px)` }}>
          {templates.map((template) => (
            <Card key={template.id}>
              <img src={template.image ?? "https://www.personalturm.de/Vorschaulink/Griesemann/2024/banner_lq_assistenz_der_bereichsleitung.jpg"} alt={template.title} />
              <h3>{template.value}</h3>
              <ChooseButton onClick={() => onTemplateSelect(template)}>Choose</ChooseButton>
            </Card>
          ))}
        </Inner>
      </CarouselWrapper>

      {totalPages > 1 && (
        <DotsContainer>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Dot key={index} active={index === currentPage} onClick={() => handleDotClick(index)} />
          ))}
        </DotsContainer>
      )}
    </Container>
  );
};

export default TemplateList;
